import React, {useState} from 'react';
import {useNotify} from 'shared-web/NotifyProvider.js';
import {RPC} from 'shared/api.js';
import Button from 'shared-web/components/Button';

export default function RefreshInvitationButton({
  deposit_guarantee_application_id,
}) {
  const [loading, setLoading] = useState(false);
  const {notify} = useNotify();

  function onClick() {
    setLoading(true);
    RPC('refreshTenantInvitation', {deposit_guarantee_application_id})
      .then(() => {
        notify({text: 'Invitation has been refreshed and sent'});
      })
      .catch((err) => {
        notify({text: err.message || err.code.toString()});
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <Button
      title="Refresh and send invitation"
      onClick={onClick}
      disabled={loading}
    />
  );
}
