import React from 'react';
import {useSelector} from 'react-redux';
import {Dropdown} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import {ReactComponent as DownSvg} from 'shared-web/components/chevron_down.svg';
import text_styles from 'shared-web/styles/text_styles.module.scss';
import classNames from 'classnames';
import Icon from 'shared-web/components/Icon.js';

import {logout} from '../../actions.js';

import styles from './UserAccount.module.scss';

export default function UserAccount() {
  const {email_address} = useSelector((state) => state.user.account);
  const history = useHistory();

  return (
    <div className={styles.container}>
      <Dropdown data-test="user-account">
        <Dropdown.Toggle as="button">
          <div className={styles.toggle}>
            <div
              className={classNames(
                styles.email_address,
                text_styles.body1_left,
              )}>
              {email_address}
            </div>
            <Icon className={styles.icon}>
              <DownSvg />
            </Icon>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu align="end">
          <Dropdown.Item
            as="button"
            onClick={() => logout(history)}
            data-test="btn-submit">
            Logout
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
