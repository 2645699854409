import { _ } from "./l10n.js";
export const VALIDATION_ERROR = Object.freeze({
    invalid: 'invalid',
    missing: 'missing',
    files_missing: 'files_missing',
    file_missing: 'file_missing',
    city_registration_missing: 'city_registration_missing',
    residence_permit_missing: 'residence_permit_missing',
    check_missing: 'check_missing',
    check_age: 'check_age',
    answer_missing: 'answer_missing',
    property_owner_answer_missing: 'property_owner_answer_missing',
    tenant_answer_missing: 'tenant_answer_missing',
    number_too_low: 'number_too_low',
    number_too_high: 'number_too_high',
    deposit_exceeds_three_times_cold_rent: 'deposit_exceeds_three_times_cold_rent',
    invalid_phone_number: 'invalid_phone_number',
    please_choose: 'please_choose',
    invalid_tax_id_number_germany: 'invalid_tax_id_number_germany',
    invalid_email: 'invalid_email',
    income_too_low_vvrb: 'income_too_low_vvrb',
    income_less_than_2x_cold_rent_vvrb: 'income_less_than_2x_cold_rent_vvrb',
    account_balance_too_low: 'account_balance_too_low',
    claim_amount_exceeds_rental_contract_deposit: 'claim_amount_exceeds_rental_contract_deposit',
    email_is_missing: 'email_is_missing',
    invalid_formance_id: 'invalid_formance_id',
    not_matching_formance_atlar_id: 'not_matching_formance_atlar_id',
    filter_invalid_column: 'filter_invalid_column',
    filter_column_missing: 'filter_column_missing',
    filter_invalid_operator: 'filter_invalid_operator',
    filter_value_missing: 'filter_value_missing',
    filter_between_invalid_value: 'filter_between_invalid_value',
    filter_min_conditions: 'filter_min_conditions',
    filter_invalid_group: 'filter_invalid_group',
    filter_invalid_group_operator: 'filter_invalid_group_operator',
    filter_invalid_field: 'filter_invalid_field',
});
export const VALIDATION_MESSAGE = Object.freeze({
    [VALIDATION_ERROR.invalid]: _('Inkorrekte Daten'),
    [VALIDATION_ERROR.missing]: _('Bitte eintragen'),
    [VALIDATION_ERROR.files_missing]: _('Bitte fügen Sie drei Einkommensnachweise hinzu'),
    [VALIDATION_ERROR.file_missing]: _('Bitte fügen Sie einen Einkommensnachweis hinzu'),
    [VALIDATION_ERROR.city_registration_missing]: _('Bitte fügen Sie eine Meldebescheinigung hinzu'),
    [VALIDATION_ERROR.residence_permit_missing]: _('Bitte fügen Sie einen Aufenthaltstitel hinzu'),
    [VALIDATION_ERROR.check_missing]: _('Bitte akzeptieren'),
    [VALIDATION_ERROR.check_age]: _('Sie müssen min. 18 Jahre alt sein'),
    [VALIDATION_ERROR.answer_missing]: _('Bitte wählen Sie eine Antwort aus'),
    [VALIDATION_ERROR.property_owner_answer_missing]: _('Bitte anklicken um einen Eigentümer auszuwählen oder zu erstellen'),
    [VALIDATION_ERROR.tenant_answer_missing]: _('Bitte anklicken um einen Mieter auszuwählen oder zu erstellen'),
    [VALIDATION_ERROR.invalid_phone_number]: _('Inkorrekte Daten'),
    [VALIDATION_ERROR.please_choose]: _('Bitte auswählen'),
    [VALIDATION_ERROR.invalid_tax_id_number_germany]: _('Inkorrekte Daten'),
    [VALIDATION_ERROR.invalid_email]: _('Bitte verwenden Sie eine gültige E-Mail (z. B. name@test.de)'),
    [VALIDATION_ERROR.account_balance_too_low]: _('Sie haben nicht genügend Geld auf diesem Kautionskonto'),
    [VALIDATION_ERROR.invalid_formance_id]: _('Inkorrekte Daten'),
    [VALIDATION_ERROR.not_matching_formance_atlar_id]: _('Formance ID und Atlar ID stimmen nicht überein'),
});
export const DEPOSIT_GUARANTEE_REASONS = Object.freeze({
    contract_running: 'contract_running',
    contract_released: 'contract_released',
    contract_deleted: 'contract_deleted',
    contract_not_running: 'contract_not_running',
    contract_rejected: 'contract_rejected',
    application_submitted: 'application_submitted',
});
export const CASH_DEPOSIT_ERROR_REASONS = Object.freeze({
    invalid_tenant_type: 'invalid_tenant_type',
});
export const DEPOSIT_TYPES = Object.freeze({
    deposit_guarantee: 'deposit_guarantee',
    cash_deposit: 'cash_deposit',
});
export const PROVIDERS = Object.freeze({
    none: 'none',
    swk: 'swk',
    vvrb: 'vvrb',
    momo: 'momo',
    all: ['swk', 'vvrb', 'momo'],
    active: ['vvrb'],
    inactive: ['swk', 'momo'],
});
export const RESIDENCE_RANGE = Object.freeze({
    less_than_or_equal_3: 'LESS_THAN_OR_EQUAL_3',
    less_than_or_equal_5: 'LESS_THAN_OR_EQUAL_5',
    less_than_or_equal_10: 'LESS_THAN_OR_EQUAL_10',
    more_than_10: 'MORE_THAN_10',
});
export const PROPERTY_TYPES = Object.freeze({
    apartment: 'apartment',
    house: 'house',
});
export const TYPES = Object.freeze({
    individual: 'individual',
    company: 'company',
});
export const INVITATION_METHOD = Object.freeze({
    sms: 'sms',
    email: 'email',
});
export const CONSENTS = Object.freeze({
    manager_terms_and_conditions: 'manager_terms_and_conditions',
    manager_deposit_guarantee_conditions: 'manager_deposit_guarantee_conditions',
    manager_cash_deposit_special_conditions: 'manager_cash_deposit_special_conditions',
});
export const TENANT_PATHS = Object.freeze({
    TenantLedOnboarding: '/tenant-led-onboarding',
    WelcomeScreen: '/willkommen',
    BasicInformationScreen: '/basisangaben-ueberpruefen',
    DepositAmountScreen: '/kaution-und-miete',
    BasicInformationScreen2: '/basisangaben-ueberpruefen/2',
    PropertyInfoScreen: '/mieteinheit-adresse',
    ManagerInfoScreen: '/hausverwaltung-adresse',
    PropertyOwnerInfoScreen: '/eigentuemer',
    RentalContractScreen: '/mietvertrag',
    AccountScreen: '/konto',
    SecondTenantScreen: '/mieter-zwei',
    SecondTenantInfoScreen: '/start-mieter1',
    CurrentAddressScreen: '/aktuelles-wohnverhaeltnis',
    CurrentAddressScreen2: '/aktuelles-wohnverhaeltnis/2',
    PreviousAddressScreen: '/vorheriges-wohnverhaeltnis',
    PreviousAddressScreen2: '/vorheriges-wohnverhaeltnis/2',
    ApplicationReviewScreen: '/antragspruefung',
    ApplicationReviewScreen2: '/antragspruefung/2',
    CurrentIncomeScreen: '/aktuelles-einkommen',
    CurrentIncomeScreen2: '/aktuelles-einkommen/2',
    PersonalDetailsScreen: '/persoenliche-angaben',
    PersonalDetailsScreen2: '/persoenliche-angaben/2',
    InterimSuccessScreen: '/start-mieter2',
    SecondPersonalDetailsScreen: '/persoenliche-angaben/2',
    SummaryScreen: '/zusammenfassung',
    SuccessScreen: '/gesendet',
    RejectFeedbackScreen: '/ablehnungsgrund',
    ThanksScreen: '/danke',
    ConfirmRefusalDecisionScreen: '/ablehnung-bestaetigen',
    RefusalCancelSuccessScreen: '/nicht-abgelehnt',
    ResendLinkScreen: '/korrigieren',
    InvalidLinkScreen: '/ungueltig',
    DataTransmittedScreen: '/uebermittelt',
    GenericErrorScreen: '/fehler',
});
export const MANAGER_PATHS = Object.freeze({
    AccountsScreen: '/konten',
    AccountCondominiumDialog: '/konten/condoKonto',
    AccountDepositDialog: '/konten/kautionskonto',
    CreateAccountDialog: '/konten/neuer_konten',
    InvitationScreen: '/passwort-erstellen',
    RequestResetPasswordScreen: '/passwortvergessen',
    ResetPasswordScreen: '/passworterneuern',
    PropertyOwnersScreen: '/eigentumer',
    CreatePropertyOwnerDialog: '/eigentumer/neu',
    DepositsScreen: '/kautionsverwaltung',
    CreateRentalContractDialog: '/kautionsverwaltung/neuer_mieter',
    TenantInvitationDialog: '/kautionsverwaltung/neu',
    CreateCashDepositDialog: '/kautionsverwaltung/neue-barkaution',
    InitiateCreditTransferDialog: '/kautionsverwaltung/barkaution/neue-auszahlung',
    DepositGuaranteeDialog: '/kautionsverwaltung/buergschaft',
    CashDepositDialog: '/kautionsverwaltung/barkaution',
    IntegrationsScreen: '/integrationen',
    CashDepositTransactionsDialog: '/kautionsverwaltung/barkaution/transaktionen',
});
export const CONDOMINIUM_TYPES = Object.freeze({
    CONDOMINIUM_FEE: 'CONDOMINIUM_FEE',
    CONDOMINIUM_RESERVE: 'CONDOMINIUM_RESERVE',
});
export const OWNER_PATHS = Object.freeze({
    PreOnboardingScreen: '/kontoeroeffnung',
    ManagerAccountOnboardedScreen: '/konto-erstellt-selbstverwaltet',
    AccountOnboardedScreen: '/konto-erstellt',
    AddManagerAccountMemberSuccessScreen: '/zugriff-erteilt',
    LinkInvalidScreen: '/link-ungueltig',
});
export const BACKOFFICE_PATHS = Object.freeze({
    accounts: '/accounts',
    managers: '/managers',
    condominiums: '/condominiums',
    rental_contracts: '/rental_contracts',
    deposits: '/deposits',
    deposit_guarantee_applications: '/deposit_guarantee_applications',
    property_owners: '/property_owners',
    claims: '/claims',
    swk_requests: '/swk_requests',
    vvrb_requests: '/vvrb_requests',
    vvrb_events: '/vvrb_events',
    everreal_events: '/everreal_events',
    covivio_events: '/covivio_events',
    tenant_promotion_campaigns: '/tenant_promotion_campaigns',
    backoffice_users: '/backoffice_users',
    deposit_accounts: '/deposit_accounts',
    deposit_account_vibans: '/deposit_account_vibans',
    cash_deposit_import: '/cash_deposit_import',
    unallocated_payments: '/unallocated_payments',
});
export const STEP_NAME = Object.freeze({
    property_info: 'property_info',
    rental_contract: 'rental_contract',
    deposit_amount: 'deposit_amount',
    account: 'account',
    property_owner_info: 'property_owner_info',
    manager_info: 'manager_info',
    second_tenant: 'second_tenant',
    current_income: 'current_income',
    application_review: 'application_review',
    personal_details: 'personal_details',
    current_address: 'current_address',
    previous_address: 'previous_address',
    basic_information_2: 'basic_information_2',
});
export const RENTAL_CONTRACT_ORIGIN = Object.freeze({
    manager: 'manager',
    tenant: 'tenant',
    covivio: 'covivio',
    everreal: 'everreal',
});
export const DEPOSIT_SUBSTATUS = Object.freeze({
    status_error: 'status_error',
    disabled: 'disabled',
    released: 'released',
    rejected_by_tenant: 'rejected_by_tenant',
    rejected_by_getmomo: 'rejected_by_getmomo',
    some_claims_paid_out: 'some_claims_paid_out',
    all_claims_paid_out: 'all_claims_paid_out',
    claim_requested: 'claim_requested',
    active_in_future: 'active_in_future',
    active: 'active',
    video_identification_required: 'video_identification_required',
    invitation_sent: 'invitation_sent',
    account_balance_empty: 'account_balance_empty',
    cash_deposit_paid_in_part: 'cash_deposit_paid_in_part',
    cash_deposit_paid_in_full: 'cash_deposit_paid_in_full',
    cash_deposit_with_outgoing_transactions: 'cash_deposit_with_outgoing_transactions',
    released_cash_deposit_with_balance: 'released_cash_deposit_with_balance',
});
export const DEPOSIT_STATUS_FILTERS = Object.freeze({
    incomplete: 'incomplete', // 'Unvollstandig',
    complete: 'complete', // 'Vollstandig',
    partially_paid_out: 'partially_paid_out', //Teilausgezahlt',
    closed: 'closed', //'Aufgelost',
    rejected: 'rejected', // Abgelehnt
});
export const statusFilterToSubStatuses = {
    [DEPOSIT_STATUS_FILTERS.incomplete]: [
        DEPOSIT_SUBSTATUS.video_identification_required,
        DEPOSIT_SUBSTATUS.invitation_sent,
        DEPOSIT_SUBSTATUS.account_balance_empty,
        DEPOSIT_SUBSTATUS.cash_deposit_paid_in_part,
    ],
    [DEPOSIT_STATUS_FILTERS.complete]: [
        DEPOSIT_SUBSTATUS.active,
        DEPOSIT_SUBSTATUS.active_in_future,
        DEPOSIT_SUBSTATUS.cash_deposit_paid_in_full,
    ],
    [DEPOSIT_STATUS_FILTERS.partially_paid_out]: [
        DEPOSIT_SUBSTATUS.some_claims_paid_out,
        DEPOSIT_SUBSTATUS.cash_deposit_with_outgoing_transactions,
        DEPOSIT_SUBSTATUS.claim_requested,
    ],
    [DEPOSIT_STATUS_FILTERS.closed]: [
        DEPOSIT_SUBSTATUS.rejected_by_tenant,
        DEPOSIT_SUBSTATUS.rejected_by_getmomo,
        DEPOSIT_SUBSTATUS.status_error,
    ],
    [DEPOSIT_STATUS_FILTERS.rejected]: [
        DEPOSIT_SUBSTATUS.disabled,
        DEPOSIT_SUBSTATUS.released,
        DEPOSIT_SUBSTATUS.released_cash_deposit_with_balance,
        DEPOSIT_SUBSTATUS.all_claims_paid_out,
    ],
};
export const BANKS = Object.freeze({
    ten31: 'ten31',
    hcob: 'hcob',
    all: ['ten31', 'hcob'],
    by_atlar_id: {
        [process.env.ATLAR_BANK_ID_TEN31 || 'ATLAR_BANK_ID_TEN31']: 'ten31',
        [process.env.ATLAR_BANK_ID_HCOB || 'ATLAR_BANK_ID_HCOB']: 'hcob',
    },
    into_atlar_id: {
        ten31: process.env.ATLAR_BANK_ID_TEN31,
        hcob: process.env.ATLAR_BANK_ID_HCOB,
    },
});
export const BANKS_IBANS = Object.freeze({
    hcob: 'DE11210500009000927837',
});
// https://github.com/get-momo/issues/issues/3155
export const MAX_INCOME_PROOF_NUMBER_OF_DAYS_IN_THE_PAST = 31;
export const EU_MEMBERS = Object.freeze([
    'AT',
    'BE',
    'BG',
    'HR',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FI',
    'FR',
    'DE',
    'GR',
    'HU',
    'IE',
    'IT',
    'LV',
    'LT',
    'LU',
    'MT',
    'MC',
    'NL',
    'PL',
    'PT',
    'RO',
    'SM',
    'SK',
    'SI',
    'ES',
    'SE',
]);
export const gender_select_options = [
    { id: 'female', name: 'Female' },
    { id: 'male', name: 'Male' },
];
export const bank_select_options = [
    { id: BANKS.ten31, name: 'TEN31 Bank AG' },
    { id: BANKS.hcob, name: 'Hamburg Commercial Bank AG' },
];
export const BANK_BIC_MAPPING = {
    hcob: 'HSHNDEHHXXX',
    ten31: 'WEGBDE77XXX',
};
export const DEFAULT_EFFECTIVE_ANNUAL_INTEREST_RATE = 0.05;
