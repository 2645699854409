import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {RPC} from 'shared/api.js';
import Button from 'shared-web/components/Button';
import {useNotify} from 'shared-web/NotifyProvider.js';

export default function ResetOnboardingButton({
  deposit_guarantee_application_id,
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {notify} = useNotify();

  function onClick() {
    setLoading(true);
    RPC('enableTenantInformationUpdate', {deposit_guarantee_application_id})
      .then(() => {
        dispatch({type: 'DEPOSIT_GUARANTEE_UPDATED'});
      })
      .then(() => {
        notify({text: 'Refresh and use the open tenant invitation button'});
      })
      .catch((err) => {
        notify({text: err.message || err.code.toString()});
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <Button
      color="primary"
      title="Reset onboarding link"
      disabled={loading}
      onClick={onClick}
    />
  );
}
