import React, {useEffect, useState} from 'react';
import Input from 'shared-web/components/Input.js';
import SeparatorLine from 'shared-web/components/SeparatorLine';
import {formatDate, formatPrice, formatShortId} from 'shared/utils.js';
import {useParams} from 'react-router-dom';
import TextArea from 'shared-web/components/TextArea.js';
import Dialog from 'shared-web/components/dialog/Dialog.js';
import {Controller, useForm} from 'react-hook-form';
import {setFormErrors} from 'shared-web/effects.js';
import Button from 'shared-web/components/Button.js';
import IconButton from 'shared-web/components/IconButton.js';

import {ReactComponent as EditSvg} from '../../assets/edit.svg';
import DepositReferenceField from '../../components/reference_field/DepositReferenceField.js';
import ManagerReferenceField from '../../components/reference_field/ManagerReferenceField.js';
import {useResource} from '../../hooks.js';
import PropertyOwnerReferenceField from '../../components/reference_field/PropertyOwnerReferenceField.js';
import {alert, handleError, confirmClose} from '../../effects.js';
import {updateRentalContract} from '../../actions.js';
import SelectManager from '../../components/SelectManager.js';
import DepositAccountVibanReferenceField from '../../components/reference_field/DepositAccountVibanReferenceField.js';

import styles from './RentalContractDialog.module.scss';

export default function RentalContractDialog({...props}) {
  const [visible, setVisible] = useState(true);
  const {id} = useParams();
  const [rental_contract] = useResource('listRentalContracts', id);

  const [readOnly, setReadOnly] = useState(true);
  const {
    control,
    register,
    formState: {isSubmitting, isDirty, errors},
    handleSubmit,
    setError,
    reset,
  } = useForm({
    mode: 'onSubmit',
  });

  useEffect(() => {
    reset(rental_contract);
  }, [reset, rental_contract]);
  const onSubmit = handleSubmit((fields) => {
    return proceed({
      fields,
      rental_contract,
      setError,
      setVisible,
      setReadOnly,
    });
  });

  const Footer = (
    <div className={styles.footer}>
      {!readOnly && (
        <Button
          title="Save"
          loading={isSubmitting}
          onClick={onSubmit}
          disabled={!isDirty}
        />
      )}
    </div>
  );

  function EditIcon() {
    return (
      <IconButton onClick={() => setReadOnly(false)}>
        <EditSvg />
      </IconButton>
    );
  }

  return (
    <Dialog
      title="Rental Contract"
      show={visible}
      footer={Footer}
      additionalIcons={rental_contract?.id && [readOnly && <EditIcon />]}
      onHide={() => confirmClose({isDirty})}
      {...props}>
      <div className={styles.two_columns}>
        <Input
          value={rental_contract?.rental_contract_id || ''}
          label="ID"
          readOnly
        />
        <Input
          value={formatShortId(rental_contract?.short_id)}
          label="Momo-ID"
          readOnly
        />

        <DepositReferenceField
          id={formatShortId(rental_contract?.deposit_id)}
          label="Deposit"
        />

        <Controller
          control={control}
          name="manager_id"
          render={({field: {name, value, onChange}}) => {
            return readOnly ? (
              <ManagerReferenceField
                id={rental_contract.manager_id}
                label="Manager"
              />
            ) : (
              <SelectManager
                label="Manager"
                name={name}
                value={value}
                onChange={onChange}
                readOnly={false}
                error={errors.manager_id?.message}
                rpc_method="listNonSurrogateManagers"
              />
            );
          }}
        />

        <Input
          value={rental_contract?.tenant_display_name}
          label="Tenant name"
          readOnly
        />

        <Input
          label="Origin"
          {...register('origin')}
          readOnly={readOnly}
          error={errors.origin?.message}
        />

        <DepositAccountVibanReferenceField
          label="Deposit Account Viban"
          id={rental_contract?.deposit_account_viban_id}
        />
      </div>

      <SeparatorLine />

      <div className={styles.two_columns}>
        <Input
          value={rental_contract?.postal_code || ''}
          label="Postal code"
          readOnly
        />
        <Input value={rental_contract?.region || ''} label="Region" readOnly />

        <Input
          value={rental_contract?.property_type || ''}
          label="Property type"
          readOnly
        />
        <Input value={rental_contract?.storey || ''} label="Storey" readOnly />

        <Input
          value={rental_contract?.street_name || ''}
          label="Street name"
          readOnly
        />
        <Input
          value={rental_contract?.street_number || ''}
          label="Street number"
          readOnly
        />

        <Input
          value={rental_contract?.tenant_phone_number || ''}
          label="Tenant phone number"
          readOnly
        />
        <PropertyOwnerReferenceField
          id={rental_contract?.property_owner_id || ''}
          label="Property owner"
        />
      </div>

      <SeparatorLine />

      <div className={styles.two_columns}>
        <Input
          value={
            rental_contract.cold_rent_cents
              ? formatPrice(rental_contract.cold_rent_cents)
              : ''
          }
          label="Cold rent"
          readOnly
        />
        <Input
          value={
            rental_contract?.deposit_amount_cents
              ? formatPrice(rental_contract.deposit_amount_cents)
              : ''
          }
          label="Deposit"
          readOnly
        />

        <Input
          value={
            rental_contract?.start_date
              ? formatDate(rental_contract.start_date)
              : ''
          }
          label="Start date"
          readOnly
        />
        <Input
          value={
            rental_contract?.created_at
              ? formatDate(rental_contract.created_at)
              : ''
          }
          label="Created at"
          readOnly
        />
      </div>

      <div className={styles.two_columns}>
        <Input
          value={
            rental_contract?.signed_date
              ? formatDate(rental_contract.signed_date)
              : ''
          }
          label="Signed date"
          readOnly
        />
      </div>

      <SeparatorLine />

      <Input
        value={rental_contract?.given_reference || ''}
        label="Given reference"
        readOnly
      />

      <TextArea value={rental_contract?.notes || ''} label="Notizen" readOnly />

      <SeparatorLine />

      <Input
        value={rental_contract?.tenant_invitation_url || ''}
        label="Tenant invitation url"
        readOnly
      />
    </Dialog>
  );
}

async function proceed({
  fields,
  rental_contract,
  setError,
  setVisible,
  setReadOnly,
}) {
  try {
    await updateRentalContract({
      id: rental_contract.id,
      origin: fields.origin,
      manager_id:
        rental_contract.manager_id === fields.manager_id
          ? undefined
          : fields.manager_id,
    });
  } catch (err) {
    if (err.data?.length) {
      setFormErrors({
        setError,
        errors: err.data,
      });
    } else if (err.message) {
      alert({title: err.message});
    } else {
      handleError(err);
    }
    return;
  }
  setVisible(false);
  setReadOnly(true);
}
