import React, {useEffect, useState} from 'react';
import Input from 'shared-web/components/Input.js';
import {formatDate} from 'shared/utils.js';
import Button from 'shared-web/components/Button.js';
import {setFormErrors} from 'shared-web/effects.js';
import {Controller, useForm} from 'react-hook-form';
import Checkbox from 'shared-web/components/checkbox/Checkbox.js';
import IconButton from 'shared-web/components/IconButton.js';
import {useParams} from 'react-router-dom';
import Dialog from 'shared-web/components/dialog/Dialog.js';
import {useNotify} from 'shared-web/NotifyProvider.js';

import {ReactComponent as EditSvg} from '../../assets/edit.svg';
import {alert, handleError, confirmClose} from '../../effects.js';
import {updateBackofficeUser} from '../../actions.js';
import {useResource} from '../../hooks.js';

import styles from './BackofficeUserDialog.module.scss';

export default function BackofficeUserDialog({...props}) {
  const [visible, setVisible] = useState(true);
  const {id} = useParams();
  const [backoffice_user] = useResource('listBackofficeUsers', id);
  const {notify} = useNotify();

  const {
    register,
    formState: {isSubmitting, isDirty, errors},
    handleSubmit,
    setError,
    control,
    reset,
  } = useForm({
    mode: 'onSubmit',
  });

  useEffect(() => {
    reset(backoffice_user);
  }, [reset, backoffice_user]);

  const onSubmit = handleSubmit((fields) =>
    proceed({fields, id, setError, backoffice_user, setVisible, notify}),
  );

  const [readOnly, setReadOnly] = useState(!!id);

  const Footer = (
    <div className={styles.footer}>
      {!readOnly && (
        <Button
          title="Save"
          loading={isSubmitting}
          onClick={onSubmit}
          disabled={!isDirty}
        />
      )}
    </div>
  );

  function EditIcon() {
    return (
      <IconButton onClick={() => setReadOnly(false)}>
        <EditSvg />
      </IconButton>
    );
  }

  return (
    <Dialog
      title="Backoffice User"
      footer={Footer}
      onHide={() => confirmClose({isDirty})}
      additionalIcons={id && [readOnly && <EditIcon />]}
      show={visible}
      {...props}>
      <Input value={id} label="ID" readOnly />
      <Input
        label="First name"
        readOnly={readOnly}
        {...register('first_name')}
        error={errors.first_name?.message}
      />
      <Input
        label="Last name"
        readOnly={readOnly}
        {...register('last_name')}
        error={errors.last_name?.message}
      />
      <Input
        label="Email address"
        readOnly={readOnly}
        {...register('email_address')}
        error={errors.email_address?.message}
      />
      {!readOnly && (
        <Input
          label="Password (min 12 characters)"
          type="password"
          autoComplete="new-password"
          {...register('password')}
          error={errors.password?.message}
        />
      )}
      <Input
        label="Created at"
        value={formatDate(backoffice_user.created_at)}
        readOnly
      />
      <Controller
        control={control}
        name="is_admin"
        render={({field: {value, onChange, name}}) => (
          <Checkbox
            id={name}
            value={value}
            disabled={readOnly}
            onChange={onChange}
            label="Admin"
          />
        )}
      />
    </Dialog>
  );
}

async function proceed({fields, id, setError, setVisible, notify}) {
  if (!fields.password) delete fields.password;

  try {
    await updateBackofficeUser({
      id,
      ...fields,
    });
  } catch (err) {
    if (err.data?.length) {
      setFormErrors({
        setError,
        errors: err.data,
      });
    } else if (err.message) {
      alert({title: err.message});
    } else {
      handleError(err);
    }
    return;
  }
  notify({text: 'The changes have been saved'});
  setVisible(false);
}
