import {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import Input from 'shared-web/components/Input.js';
import Button from 'shared-web/components/Button.js';
import {setFormErrors} from 'shared-web/effects.js';
import IconButton from 'shared-web/components/IconButton.js';
import {useParams} from 'react-router-dom';
import Dialog from 'shared-web/components/dialog/Dialog.js';
import {useNotify} from 'shared-web/NotifyProvider.js';

import {updateCondominium} from '../../actions.js';
import {alert, handleError, confirmClose} from '../../effects.js';
import {ReactComponent as EditSvg} from '../../assets/edit.svg';
import {useGetOneResource} from '../../hooks.js';
import SelectManager from '../../components/SelectManager.js';
import ManagerReferenceField from '../../components/reference_field/ManagerReferenceField.js';

import styles from './CondominiumDialog.module.scss';

export default function CondominiumDialog({...props}) {
  const [visible, setVisible] = useState(true);
  const {id} = useParams();
  const [condominium] = useGetOneResource('getCondominium', id);
  const {notify} = useNotify();

  const [readOnly, setReadOnly] = useState(true);
  const {
    register,
    formState: {isSubmitting, isDirty, errors},
    handleSubmit,
    setError,
    reset,
    control,
  } = useForm({
    mode: 'onSubmit',
  });

  useEffect(() => {
    reset(condominium);
  }, [condominium, reset]);

  const onSubmit = handleSubmit((fields) => {
    return proceed({
      fields,
      condominium,
      setError,
      setVisible,
      setReadOnly,
      notify,
    });
  });

  const Footer = (
    <div className={styles.footer}>
      {!readOnly && (
        <Button
          title="Save"
          loading={isSubmitting}
          onClick={onSubmit}
          disabled={!isDirty}
        />
      )}
    </div>
  );

  function EditIcon() {
    return (
      <IconButton onClick={() => setReadOnly(false)}>
        <EditSvg />
      </IconButton>
    );
  }

  return (
    <Dialog
      title="Condominium"
      show={visible}
      footer={Footer}
      additionalIcons={condominium?.id && [readOnly && <EditIcon />]}
      onHide={() => confirmClose({isDirty})}
      {...props}>
      {condominium?.id && <Input value={condominium.id} label="ID" readOnly />}
      {readOnly ? (
        <>
          <ManagerReferenceField id={condominium.managerId} label="Manager" />
        </>
      ) : (
        <Controller
          control={control}
          name="managerId"
          readOnly={readOnly}
          render={({field: {name, value, onChange}}) => (
            <SelectManager
              label="Manager"
              name={name}
              value={value}
              onChange={onChange}
              readOnly={false}
              error={errors.updateData?.manager_id?.message}
              rpc_method="listNonSurrogateManagers"
            />
          )}
        />
      )}

      <Input
        label="Title"
        readOnly={readOnly}
        {...register('title')}
        error={errors.updateData?.title?.message}
      />

      <Input
        label="Legal name"
        readOnly={readOnly}
        {...register('legalName')}
        error={errors.updateData?.legalName?.message}
      />

      <Input
        label="Street name"
        readOnly={readOnly}
        {...register('streetName')}
        error={errors.updateData?.streetName?.message}
      />

      <Input
        label="Street number"
        readOnly={readOnly}
        {...register('streetNumber')}
        error={errors.updateData?.streetNumber?.message}
      />

      <Input
        label="Postal Code"
        readOnly={readOnly}
        {...register('postalCode')}
        error={errors.updateData?.postalCode?.message}
      />

      <Input
        label="Region"
        readOnly={readOnly}
        {...register('region')}
        error={errors.updateData?.region?.message}
      />
    </Dialog>
  );
}

async function proceed({
  fields,
  condominium,
  setError,
  setVisible,
  setReadOnly,
  notify,
}) {
  if (fields.type === 'individual') delete fields.company_name;

  try {
    await updateCondominium({
      id: condominium.id,
      version: condominium.version,
      updateData: {...fields},
    });
  } catch (err) {
    if (err.data?.length) {
      setFormErrors({
        setError,
        errors: err.data,
      });
    } else if (err.message) {
      alert({title: err.message});
    } else {
      handleError(err);
    }
    return;
  }
  notify({text: 'The changes have been saved.'});
  setVisible(false);
  setReadOnly(true);
}
