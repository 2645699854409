import { DEPOSIT_TYPES } from "../constants.js";
const statusMap = {
    [DEPOSIT_TYPES.deposit_guarantee]: 'Bürgschaft',
    [DEPOSIT_TYPES.cash_deposit]: 'Barkaution',
};
/**
 * Retrieves the deposit status based on the provided deposit type.
 *
 * @param deposit - The deposit object containing the deposit type.
 * @returns The corresponding deposit status as a string. If the deposit or deposit type is not provided, returns an empty string.
 */
export function getDepositStatus(deposit) {
    if (!deposit || !deposit.deposit_type)
        return '';
    return statusMap[deposit.deposit_type] || '';
}
