export function getClaimDetails(claims) {
    let total_claim_cents = 0;
    let total_paid_out_claims_cents = 0;
    let total_open_claim_cents = 0;
    if ((claims === null || claims === void 0 ? void 0 : claims.length) > 0) {
        for (const { amount_cents, closed_at } of claims) {
            total_claim_cents += amount_cents;
            if (closed_at) {
                total_paid_out_claims_cents += amount_cents;
            }
            else {
                total_open_claim_cents += amount_cents;
            }
        }
    }
    return {
        total_claim_cents,
        total_paid_out_claims_cents,
        total_open_claim_cents,
    };
}
