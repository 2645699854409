var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/**
 * Downloads a file from a Blob, Response, or data URL.
 * @param {Blob|Response|string} data - The data to download (Blob, Response object, or data URL).
 * @param {string} [filename] - The name of the downloaded file (default: `download-{timestamp}`).
 */
export function downloadFile(data_1) {
    return __awaiter(this, arguments, void 0, function* (data, filename = `download-${Date.now()}`) {
        let objectUrl;
        if (data instanceof Response) {
            objectUrl = URL.createObjectURL(yield data.blob());
        }
        else {
            throw new TypeError('Unsupported data type for download');
        }
        // Create and trigger download
        const a = document.createElement('a');
        a.setAttribute('href', objectUrl);
        a.setAttribute('download', filename);
        a.setAttribute('target', '_blank');
        document.body.append(a);
        a.click();
        a.remove();
    });
}
