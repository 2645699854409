var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getApiUrl } from "./utils.js";
const { REACT_APP_API_URL } = process.env;
// const API_URL = getApiUrl(REACT_APP_API_URL);
export const CONFLICT = 'CONFLICT';
export const BAD_REQUEST = 'BAD_REQUEST';
export const NOT_FOUND = 'NOT_FOUND';
export const FORBIDDEN = 'FORBIDDEN';
export const UNAUTHORIZED = 'UNAUTHORIZED';
export const MANAGER_PROFILE_INCOMPLETE = 'MANAGER_PROFILE_INCOMPLETE';
export function request(path_1) {
    return __awaiter(this, arguments, void 0, function* (path, options = {}, API_URL = REACT_APP_API_URL) {
        const headers = new Headers(options.headers);
        if (options.body instanceof URLSearchParams) {
            options.body = options.body.toString();
            if (!headers.has('content-type')) {
                headers.set('content-type', 'application/x-www-form-urlencoded;charset=UTF-8');
            }
            // TODO: only JSON.stringify if the constructor
            // is Object or null instead
        }
        else if (typeof options.body === 'object' && !isBlob(options.body)) {
            options.body = JSON.stringify(options.body);
            if (!headers.has('content-type')) {
                headers.set('content-type', 'application/json;charset=UTF-8');
            }
        }
        return fetch(new URL(path, new URL(getApiUrl(API_URL))), Object.assign(Object.assign({}, options), { headers }));
    });
}
export function RPC(method_1, params_1) {
    return __awaiter(this, arguments, void 0, function* (method, params, { headers, API_URL, signal } = {}) {
        const res = yield request(method, {
            credentials: 'include',
            method: 'POST',
            headers: Object.assign({ 'Content-Type': 'application/json', Accept: 'application/json' }, headers),
            signal,
            body: params,
        }, API_URL);
        let result, error;
        if (res.status === 401) {
            error = {
                code: UNAUTHORIZED,
            };
        }
        else if (res.ok) {
            ({ result, error } = yield res.json());
        }
        else {
            error = new Error(`${res.status} ${res.statusText}`);
        }
        if (error) {
            // eslint-disable-next-line no-console
            console.error(method, error);
            throw error;
        }
        return result;
    });
}
// Node.js only
export function login(url, username, password) {
    return __awaiter(this, void 0, void 0, function* () {
        const res = yield fetch(new URL('login', url), {
            method: 'POST',
            body: JSON.stringify({ email_address: username, password }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });
        if (!res.ok) {
            // eslint-disable-next-line no-console
            console.error(res.status, res.statusText);
            // eslint-disable-next-line unicorn/no-process-exit
            process.exit(1);
        }
        return res.headers.get('set-cookie');
    });
}
function isBlob(data) {
    return typeof Blob !== 'undefined' && data instanceof Blob;
}
