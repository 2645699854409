import {useForm} from 'react-hook-form';
import Button from 'shared-web/components/Button.js';
import {setFormErrors} from 'shared-web/effects.js';
import Dialog from 'shared-web/components/dialog/Dialog.js';
import {useEffect, useState} from 'react';
import {useNotify} from 'shared-web/NotifyProvider.js';
import {
  BANK_BIC_MAPPING,
  DEFAULT_EFFECTIVE_ANNUAL_INTEREST_RATE,
} from 'shared/constants.js';

import {alert, handleError, confirmClose} from '../../effects.js';
import {createAccount} from '../../actions.js';

export default function CreateAccountDialog({...props}) {
  const [visible, setVisible] = useState(true);
  const {notify} = useNotify();
  const {
    formState: {isSubmitting, isDirty},
    handleSubmit,
    setError,
    setValue,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      effective_annual_interest_rate: DEFAULT_EFFECTIVE_ANNUAL_INTEREST_RATE,
    },
  });
  const onSubmit = handleSubmit((fields) =>
    proceed({fields, setError, setVisible, notify}),
  );

  const selectedBank = watch('bank');

  useEffect(() => {
    if (selectedBank) {
      setValue('bic', BANK_BIC_MAPPING[selectedBank], {shouldDirty: true});
    }
  }, [selectedBank, setValue]);

  const Footer = (
    <Button
      title="Save"
      loading={isSubmitting}
      onClick={onSubmit}
      disabled={!isDirty}
    />
  );

  return (
    <Dialog
      show={visible}
      title="Create Account"
      footer={Footer}
      onHide={() => confirmClose({isDirty})}
      {...props}></Dialog>
  );
}

async function proceed({fields, setError, setVisible, notify}) {
  try {
    await createAccount(fields);
  } catch (err) {
    if (err.data?.length) {
      setFormErrors({
        setError,
        errors: err.data,
      });
    } else if (err.message) {
      alert({title: err.message});
    } else {
      handleError(err);
    }
    return;
  }
  notify({text: 'Account was created.'});
  setVisible(false);
}
