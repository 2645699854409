import {useForm, Controller} from 'react-hook-form';
import Input from 'shared-web/components/Input.js';
import Button from 'shared-web/components/Button.js';
import {setFormErrors} from 'shared-web/effects.js';
import Dialog from 'shared-web/components/dialog/Dialog.js';
import {useState} from 'react';
import {useNotify} from 'shared-web/NotifyProvider.js';

import {alert, handleError, confirmClose} from '../../effects.js';
import {createCondominium} from '../../actions.js';
import SelectManager from '../../components/SelectManager.js';

export default function CreateCondominiumDialog({...props}) {
  const [visible, setVisible] = useState(true);
  const {notify} = useNotify();
  const {
    control,
    register,
    formState: {isSubmitting, isDirty, errors},
    handleSubmit,
    setError,
  } = useForm({
    mode: 'onChange',
  });
  const onSubmit = handleSubmit((fields) =>
    proceed({fields, setError, setVisible, notify}),
  );

  const Footer = (
    <Button
      title="Save"
      loading={isSubmitting}
      onClick={onSubmit}
      disabled={!isDirty}
    />
  );

  return (
    <Dialog
      show={visible}
      title="Create Condominium"
      footer={Footer}
      onHide={() => confirmClose({isDirty})}
      {...props}>
      <Controller
        control={control}
        name="managerId"
        render={({field: {name, value, onChange}}) => (
          <SelectManager
            label="Manager"
            name={name}
            value={value}
            onChange={onChange}
            readOnly={false}
            error={errors.createData?.manager_id?.message}
            rpc_method="listNonSurrogateManagers"
          />
        )}
      />
      <Input
        label="Title"
        {...register('title')}
        error={errors.createData?.legalName?.message}
      />
      <Input
        label="Legal name"
        {...register('legalName')}
        error={errors.createData?.legalName?.message}
      />
      <Input
        label="Street name"
        {...register('streetName')}
        error={errors.createData?.streetName?.message}
      />
      <Input
        label="Street number"
        {...register('streetNumber')}
        error={errors.createData?.streetNumber?.message}
      />
      <Input
        label="Postal Code"
        {...register('postalCode')}
        error={errors.createData?.postalCode?.message}
      />
      <Input
        label="Region"
        {...register('region')}
        error={errors.createData?.region?.message}
      />
    </Dialog>
  );
}

async function proceed({fields, setError, setVisible, notify}) {
  try {
    await createCondominium({createData: {...fields}});
  } catch (err) {
    if (err.data?.length) {
      setFormErrors({
        setError,
        errors: err.data,
      });
    } else if (err.message) {
      alert({title: err.message});
    } else {
      handleError(err);
    }
    return;
  }
  notify({text: 'Condominium was created.'});
  setVisible(false);
}
