export function formatDate(date) {
    return new Date(date).toLocaleDateString('de', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });
}
export function formatDateShort(date) {
    return new Date(date).toLocaleDateString('de', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
    });
}
