import React, {useRef} from 'react';
import Button from 'shared-web/components/Button.js';
import classNames from 'classnames';
import {useNotify} from 'shared-web/NotifyProvider.js';
import text_styles from 'shared-web/styles/text_styles.module.scss';
import {BAD_REQUEST, RPC} from 'shared/api.js';
import ReactJson from 'react-json-view';

import {alert} from '../../effects.js';

import styles from './BulkImportButtons.module.scss';

export function BulkImportCreateCashDepositButton() {
  const fileInputRef = useRef(null);
  const {notify} = useNotify();

  return (
    <>
      <input
        type="file"
        style={{display: 'none'}}
        ref={fileInputRef}
        accept="text/csv"
        onChange={(ev) =>
          handleFileUpload(ev, {notify}, 'bulkImportCreateCashDeposit')
        }
      />
      <Button
        title="Bulk Create Cash Deposits"
        onClick={() => {
          fileInputRef.current.click();
        }}
      />
    </>
  );
}

export function BulkImportAllocateTransactionButton() {
  const fileInputRef = useRef(null);
  const {notify} = useNotify();

  return (
    <>
      <input
        type="file"
        style={{display: 'none'}}
        ref={fileInputRef}
        accept="text/csv"
        onChange={(ev) =>
          handleFileUpload(ev, {notify}, 'bulkImportAllocateTransaction')
        }
      />
      <Button
        title="Bulk Transfer Money"
        onClick={() => {
          fileInputRef.current.click();
        }}
      />
    </>
  );
}

async function handleFileUpload(ev, {notify}, rpc_endpoint) {
  const bulkImportCsvFile = ev.target.files[0];
  if (!bulkImportCsvFile) return;

  const bulkImportCsv = await bulkImportCsvFile.text();

  try {
    await RPC(rpc_endpoint, {
      bulkImportCsv,
      filename: bulkImportCsvFile.name,
    });
    notify({text: 'Bulk Import CSV successfully uploaded'});
  } catch (err) {
    if (err.code === BAD_REQUEST && err.data) {
      alert({
        is_wide_modal: true,
        hide_image: true,
        title: 'Validation error',
        text: (
          <>
            <p className={classNames(text_styles.body2_centered, styles.text)}>
              {`The file "${bulkImportCsvFile.name}" contains invalid data.`}
            </p>
            <p className={classNames(text_styles.body2_centered, styles.text)}>
              The validation errors in the following are displayed in their raw
              format. The paths start with <code>/&lt;row_index&gt;</code>, in
              the messages they are being referenced with
              <code>[&lt;row_index&gt;].&lt;column_name&gt;</code>. If you take
              the <code>&lt;row_index&gt;</code> and add 2 to that, you get the
              number of the row in excel (or the CSV line number) as a result.
            </p>
            <p className={classNames(text_styles.body2_centered, styles.text)}>
              Please correct the errors and proceed to attempt a new upload.
            </p>
            <div className={styles.error_details}>
              <ReactJson
                collapsed={2}
                displayObjectSize={false}
                displayDataTypes={false}
                src={err.data}
              />
            </div>
          </>
        ),
      });
    } else {
      alert({text: err.message || err.code.toString()});
    }
  } finally {
    ev.target.value = null;
  }
}
