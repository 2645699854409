import {useSelector} from 'react-redux';
import {formatDate} from 'shared/utils.js';
import {useHistory, Switch, Route} from 'react-router-dom';
import {BACKOFFICE_PATHS} from 'shared/constants.js';

import Pagination from '../../components/Pagination.js';
import Layout from '../../components/layout/Layout.js';
import ButtonAdd from '../../components/button_add/ButtonAdd.js';
import SearchInput from '../../components/search_input/SearchInput.js';
import Table from '../../components/Table.js';
import {useFetchTableData} from '../../hooks.js';
import ManagerReferenceField from '../../components/reference_field/ManagerReferenceField.js';

import styles from './CondominiumsScreen.module.scss';
import CreateCondominiumDialog from './CreateCondominiumDialog.js';
import CondominiumDialog from './CondominiumDialog.js';

export default function CondominiumsScreen() {
  const {refresh} = useSelector((state) => state.condominiums);

  const {
    orderBy,
    resetTable,
    setSearchQuery,
    sort_column,
    sort_direction,
    entries_per_page,
    loading,
    rows,
    total,
    setOffset,
    offset,
  } = useFetchTableData({
    method: 'getCondominiums',
    refresh,
    default_sort_column: 'createdAt',
  });

  const history = useHistory();

  function routeToListScreen() {
    history.push(BACKOFFICE_PATHS.condominiums);
  }

  return (
    <Layout
      button={<ButtonAddCondominium {...{resetTable, history}} />}
      search={<SearchInput onValue={setSearchQuery} />}>
      <Switch>
        <Route path={`${BACKOFFICE_PATHS.condominiums}/new`}>
          <CreateCondominiumDialog onExited={routeToListScreen} />
        </Route>
        <Route path={`${BACKOFFICE_PATHS.condominiums}/:id`}>
          <CondominiumDialog onExited={routeToListScreen} />
        </Route>
      </Switch>

      <div className={styles.content}>
        <Table
          data={rows}
          onOpen={(condominium) =>
            history.push(`${BACKOFFICE_PATHS.condominiums}/${condominium.id}`)
          }
          orderBy={orderBy}
          sort_column={sort_column}
          sort_direction={sort_direction}
          loading={loading}
          columns={columns}
        />
        {!!total && (
          <Pagination
            total={total}
            offset={offset}
            setOffset={setOffset}
            per_page={entries_per_page}
          />
        )}
      </div>
    </Layout>
  );
}

function ButtonAddCondominium({resetTable, history}) {
  return (
    <ButtonAdd
      title="Create Condominium"
      onClick={() => {
        resetTable();
        history.push(`${BACKOFFICE_PATHS.condominiums}/new`);
      }}
      className={styles.add_button}
    />
  );
}

const columns = [
  {
    id: 'id',
    Header: 'ID',
    accessor: 'id',
    text_styles: 'body2',
    sort: 'id',
  },
  {
    id: 'title',
    Header: 'Title',
    accessor: 'title',
    text_styles: 'body2',
    sort: 'title',
  },
  {
    id: 'legalName',
    Header: 'Legal Name',
    accessor: 'legalName',
    text_styles: 'body2',
    sort: 'legalName',
  },
  {
    id: 'address',
    Header: 'Address',
    accessor: (row) => {
      return `${row.streetName} ${row.streetNumber}, ${row.postalCode} ${row.region}`;
    },
    text_styles: 'body2',
    sort: 'streetName',
  },
  {
    id: 'managerId',
    Header: 'Manager',
    accessor: 'managerId',
    Cell: ({value}) => <ManagerReferenceField id={value} />,
    text_styles: 'body2',
    sort: 'managerId',
  },
  {
    id: 'createdAt',
    Header: 'Created at',
    accessor: 'createdAt',
    Cell: ({value}) => formatDate(value),
    text_styles: 'body2',
    sort: 'createdAt',
  },
];
