import React, {useState, useEffect} from 'react';
import Input from 'shared-web/components/Input.js';
import {formatDate, formatDecimal} from 'shared/utils.js';
import {useParams} from 'react-router-dom';
import Dialog from 'shared-web/components/dialog/Dialog.js';
import {useForm} from 'react-hook-form';
import IconButton from 'shared-web/components/IconButton.js';
import {setFormErrors} from 'shared-web/effects.js';
import Button from 'shared-web/components/Button.js';
import Select from 'shared-web/components/Select.js';
import {bank_select_options} from 'shared/constants.js';

import {useResource} from '../../hooks.js';
import ManagerReferenceField from '../../components/reference_field/ManagerReferenceField.js';
import {ReactComponent as EditSvg} from '../../assets/edit.svg';
import {handleError, confirmClose} from '../../effects.js';
import {updateDepositAccount} from '../../actions.js';

import UploadVibansButton from './UploadVibansButton.js';
import styles from './DepositAccountDialog.module.scss';

export default function DepositAccountDialog({...props}) {
  const [visible, setVisible] = useState(true);
  const {id} = useParams();
  const [deposit_account] = useResource('listDepositAccounts', id);

  const [readOnly, setReadOnly] = useState(true);
  const {
    register,
    formState: {isSubmitting, isDirty, errors},
    handleSubmit,
    setError,
    reset,
  } = useForm({
    mode: 'onSubmit',
  });

  useEffect(() => {
    reset(deposit_account);
  }, [reset, deposit_account]);

  const onSubmit = handleSubmit((fields) => {
    return proceed({
      fields,
      deposit_account,
      setError,
      setVisible,
      setReadOnly,
    });
  });

  const Footer = (
    <div className={styles.footer}>
      {!readOnly && (
        <Button
          title="Save"
          loading={isSubmitting}
          onClick={onSubmit}
          disabled={!isDirty}
        />
      )}
    </div>
  );

  function EditIcon() {
    return (
      <IconButton onClick={() => setReadOnly(false)}>
        <EditSvg />
      </IconButton>
    );
  }

  return (
    <Dialog
      title="Deposit Account"
      show={visible}
      footer={Footer}
      additionalIcons={deposit_account?.id && [readOnly && <EditIcon />]}
      onHide={() => confirmClose({isDirty})}
      {...props}>
      <div>
        <Input value={id} label="ID" readOnly />
        <Input value={deposit_account.iban} label="IBAN" readOnly />
        <Input value={deposit_account.bic} label="BIC" readOnly />
        <ManagerReferenceField
          id={deposit_account.manager_id}
          label="Manager"
        />
        <Input
          value={formatDate(deposit_account.created_at)}
          label="Created at"
          readOnly
        />
        <Input
          label="Formance Account ID"
          readOnly={readOnly}
          error={errors.formance_account_id?.message}
          {...register('formance_account_id')}
        />
        <Input
          label="Atlar Account ID"
          readOnly={readOnly}
          error={errors.atlar_account_id?.message}
          {...register('atlar_account_id')}
        />
        <Input
          label="Bank External Reference"
          readOnly={readOnly}
          error={errors.bank_external_reference?.message}
          {...register('bank_external_reference')}
        />
        <Select
          label="Bank"
          options={bank_select_options}
          error={errors.bank?.message}
          readOnly={readOnly}
          {...register('bank')}
          className={styles.bank}
        />
        <Input
          value={deposit_account.total_vibans}
          label="Total Vibans"
          readOnly
        />
        <Input
          value={deposit_account.allocated_vibans}
          label="Allocated Vibans"
          readOnly
        />

        <Input
          value={
            deposit_account.effective_annual_interest_rate &&
            formatDecimal(deposit_account.effective_annual_interest_rate)
          }
          label="Effective Annual Interest Rate (%)"
          readOnly
        />

        {deposit_account?.iban && (
          <UploadVibansButton
            deposit_account_id={deposit_account.id}
            setVisible={setVisible}
          />
        )}
      </div>
    </Dialog>
  );
}

async function proceed({fields, setError, setVisible, setReadOnly}) {
  try {
    await updateDepositAccount({
      id: fields.id,
      updateData: {
        formanceId: fields.formance_account_id,
        atlarId: fields.atlar_account_id,
        bankExternalReference: fields.bank_external_reference,
        bank: fields.bank,
      },
    });
  } catch (err) {
    if (err.data?.length) {
      setFormErrors({
        setError,
        errors: err.data,
      });
    } else {
      handleError(err);
    }
    return;
  }
  setVisible(false);
  setReadOnly(true);
}
