import React, {useState, useEffect} from 'react';
import Input from 'shared-web/components/Input.js';
import {formatDate, formatDecimal} from 'shared/utils.js';
import {useParams} from 'react-router-dom';
import Dialog from 'shared-web/components/dialog/Dialog.js';
import {useForm} from 'react-hook-form';
import {setFormErrors} from 'shared-web/effects.js';
import Button from 'shared-web/components/Button.js';
import Select from 'shared-web/components/Select.js';
import {BACKOFFICE_PATHS, bank_select_options} from 'shared/constants.js';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min.js';

import {useGetOneResource} from '../../hooks.js';
import ManagerReferenceField from '../../components/reference_field/ManagerReferenceField.js';
import {handleError, confirmClose} from '../../effects.js';
import {updateAccount} from '../../actions.js';

import styles from './AccountDialog.module.scss';

export default function AccountDialog({...props}) {
  const [visible, setVisible] = useState(true);
  const history = useHistory();
  const {id, accountType} = useParams();
  const [account, error] = useGetOneResource('getAccount', id, {
    accountType,
  });

  const [readOnly, setReadOnly] = useState(true);
  const {
    register,
    formState: {isSubmitting, isDirty, errors},
    handleSubmit,
    setError,
    reset,
  } = useForm({
    mode: 'onSubmit',
  });

  useEffect(() => {
    if (!account.id) {
      return;
    }
    reset(account);
    return () => reset();
  }, [reset, account]);

  if (error) {
    history.push(BACKOFFICE_PATHS.accounts);
  }

  if (!account.id) {
    return null;
  }

  const onSubmit = handleSubmit((fields) => {
    return proceed({
      fields,
      account,
      setError,
      setVisible,
      setReadOnly,
    });
  });

  const Footer = (
    <div className={styles.footer}>
      {!readOnly && (
        <Button
          title="Save"
          loading={isSubmitting}
          onClick={onSubmit}
          disabled={!isDirty}
        />
      )}
    </div>
  );

  return (
    <Dialog
      title="Account"
      show={visible}
      footer={Footer}
      onHide={() => confirmClose({isDirty})}
      {...props}>
      <div>
        <Input value={account.id} label="ID" readOnly />
        <Input value={account.accountType} label="Type" readOnly />
        <Input value={account.accountIban} label="IBAN" readOnly />
        <Input value={account.accountBic} label="BIC" readOnly />
        <ManagerReferenceField id={account.managerId} label="Manager" />
        <Input
          value={formatDate(account.createdAt)}
          label="Created at"
          readOnly
        />
        <Select
          label="Bank"
          options={bank_select_options}
          error={errors.bank?.message}
          readOnly={readOnly}
          {...register('accountBank')}
          className={styles.bank}
        />
        <Input
          value={
            account.effectiveAnnualInterestRate &&
            formatDecimal(account.effectiveAnnualInterestRate)
          }
          label="Effective Annual Interest Rate (%)"
          readOnly
        />
      </div>
    </Dialog>
  );
}

async function proceed({fields, setError, setVisible, setReadOnly}) {
  try {
    await updateAccount({
      id: fields.id,
      formance_account_id: fields.formance_account_id,
      atlar_account_id: fields.atlar_account_id,
      bank_external_reference: fields.bank_external_reference,
      bank: fields.bank,
    });
  } catch (err) {
    if (err.data?.length) {
      setFormErrors({
        setError,
        errors: err.data,
      });
    } else {
      handleError(err);
    }
    return;
  }
  setVisible(false);
  setReadOnly(true);
}
