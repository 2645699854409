import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {BACKOFFICE_PATHS} from 'shared/constants.js';

import LoginScreen from './scenes/authentication/LoginScreen.js';
import ManagersScreen from './scenes/managers/ManagersScreen.js';
import RentalContractsScreen from './scenes/rentalContracts/RentalContractsScreen.js';
import DepositsScreen from './scenes/deposits/DepositsScreen.js';
import ClaimsScreen from './scenes/claims/ClaimsScreen.js';
import DepositGuaranteeApplicationsScreen from './scenes/depositGuaranteeApplications/DepositGuaranteeApplicationsScreen.js';
import PropertyOwnersScreen from './scenes/propertyOwners/PropertyOwnersScreen.js';
import BackofficeUsersScreen from './scenes/backofficeUsers/BackofficeUsersScreen.js';
import VvrbRequestsScreens from './scenes/vvrbRequests/VvrbRequestsScreen.js';
import VvrbEventsScreen from './scenes/vvrbEvents/VvrbEventsScreen.js';
import EverRealEventsScreen from './scenes/everRealEvents/EverRealEventsScreen.js';
import CovivioEventsScreen from './scenes/covivioEvents/CovivioEventsScreen.js';
import TenantPromotionCampaignsScreen from './scenes/tenantPromotionCampaigns/TenantPromotionCampaignsScreen.js';
import DepositAccountsScreen from './scenes/depositAccounts/DepositAccountsScreen.js';
import DepositAccountVibansScreen from './scenes/depositAccountVibans/DepositAccountVibansScreen.js';
import CashDepositImportScreen from './scenes/cashDepositImport/CashDepositImportScreen.js';
import UnallocatedPaymentsScreen from './scenes/unallocatedPayments/UnallocatedPaymentsScreen.js';
import CondominiumsScreen from './scenes/condominiums/CondominiumsScreen.js';
import AccountsScreen from './scenes/accounts/AccountsScreen.js';

export default function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <PrivateRoute path={BACKOFFICE_PATHS.backoffice_users}>
          <BackofficeUsersScreen />
        </PrivateRoute>
        <PrivateRoute path={BACKOFFICE_PATHS.property_owners}>
          <PropertyOwnersScreen />
        </PrivateRoute>
        <PrivateRoute path={BACKOFFICE_PATHS.deposit_guarantee_applications}>
          <DepositGuaranteeApplicationsScreen />
        </PrivateRoute>
        <PrivateRoute path={BACKOFFICE_PATHS.rental_contracts}>
          <RentalContractsScreen />
        </PrivateRoute>
        <PrivateRoute path={BACKOFFICE_PATHS.deposits}>
          <DepositsScreen />
        </PrivateRoute>
        <PrivateRoute path={BACKOFFICE_PATHS.claims}>
          <ClaimsScreen />
        </PrivateRoute>
        <PrivateRoute path={BACKOFFICE_PATHS.vvrb_requests}>
          <VvrbRequestsScreens />
        </PrivateRoute>
        <PrivateRoute path={BACKOFFICE_PATHS.vvrb_events}>
          <VvrbEventsScreen />
        </PrivateRoute>

        <PrivateRoute path={BACKOFFICE_PATHS.everreal_events}>
          <EverRealEventsScreen />
        </PrivateRoute>

        <PrivateRoute path={BACKOFFICE_PATHS.covivio_events}>
          <CovivioEventsScreen />
        </PrivateRoute>

        <PrivateRoute path={BACKOFFICE_PATHS.tenant_promotion_campaigns}>
          <TenantPromotionCampaignsScreen />
        </PrivateRoute>

        <PrivateRoute path={BACKOFFICE_PATHS.managers}>
          <ManagersScreen />
        </PrivateRoute>

        <PrivateRoute path={BACKOFFICE_PATHS.condominiums}>
          <CondominiumsScreen />
        </PrivateRoute>

        <PrivateRoute path={BACKOFFICE_PATHS.deposit_accounts}>
          <DepositAccountsScreen />
        </PrivateRoute>

        <PrivateRoute path={BACKOFFICE_PATHS.accounts}>
          <AccountsScreen />
        </PrivateRoute>

        <PrivateRoute path={BACKOFFICE_PATHS.deposit_account_vibans}>
          <DepositAccountVibansScreen />
        </PrivateRoute>

        <PrivateRoute path={BACKOFFICE_PATHS.cash_deposit_import}>
          <CashDepositImportScreen />
        </PrivateRoute>

        <PrivateRoute path={BACKOFFICE_PATHS.unallocated_payments}>
          <UnallocatedPaymentsScreen />
        </PrivateRoute>

        <Route path="/*">
          <Redirect to={BACKOFFICE_PATHS.managers} />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

function PrivateRoute({children, ...props}) {
  const status = useSelector((state) => state.user.status);

  if (status === 'loading') return null;

  return (
    <Route
      {...props}
      render={() => (status === 'logged_in' ? children : <LoginScreen />)}
    />
  );
}
