import {useForm, Controller} from 'react-hook-form';
import Input from 'shared-web/components/Input.js';
import Button from 'shared-web/components/Button.js';
import {setFormErrors} from 'shared-web/effects.js';
import Dialog from 'shared-web/components/dialog/Dialog.js';
import {useEffect, useState} from 'react';
import {useNotify} from 'shared-web/NotifyProvider.js';
import Select from 'shared-web/components/Select.js';
import {
  BANK_BIC_MAPPING,
  bank_select_options,
  DEFAULT_EFFECTIVE_ANNUAL_INTEREST_RATE,
} from 'shared/constants.js';
import NumberInput from 'shared-web/components/NumberInput.js';

import {alert, handleError, confirmClose} from '../../effects.js';
import {createDepositAccount} from '../../actions.js';
import SelectManager from '../../components/SelectManager.js';

export default function CreateDepositAccountDialog({...props}) {
  const [visible, setVisible] = useState(true);
  const {notify} = useNotify();
  const {
    control,
    register,
    formState: {isSubmitting, isDirty, errors},
    handleSubmit,
    setError,
    setValue,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      effective_annual_interest_rate: DEFAULT_EFFECTIVE_ANNUAL_INTEREST_RATE,
    },
  });
  const onSubmit = handleSubmit((fields) =>
    proceed({fields, setError, setVisible, notify}),
  );

  const selectedBank = watch('bank');

  useEffect(() => {
    if (selectedBank) {
      setValue('bic', BANK_BIC_MAPPING[selectedBank], {shouldDirty: true});
    }
  }, [selectedBank, setValue]);

  const Footer = (
    <Button
      title="Save"
      loading={isSubmitting}
      onClick={onSubmit}
      disabled={!isDirty}
    />
  );

  return (
    <Dialog
      show={visible}
      title="Create Deposit Account"
      footer={Footer}
      onHide={() => confirmClose({isDirty})}
      {...props}>
      <Controller
        control={control}
        name="manager_id"
        render={({field: {name, value, onChange}}) => (
          <SelectManager
            label="Manager"
            name={name}
            value={value}
            onChange={onChange}
            readOnly={false}
            error={errors.manager_id?.message}
            rpc_method="listNonSurrogateManagers"
          />
        )}
      />
      <Select
        label="Bank"
        options={bank_select_options}
        error={errors.bank?.message}
        {...register('bank')}
      />
      <Input label="IBAN" {...register('iban')} error={errors.iban?.message} />
      <Input label="BIC" {...register('bic')} error={errors.bic?.message} />
      <Input
        label="Bank External Reference"
        {...register('bank_external_reference')}
        error={errors.bank_external_reference?.message}
      />
      <Controller
        control={control}
        name="effective_annual_interest_rate"
        render={({field: {value, onChange, name}}) => (
          <NumberInput
            value={value}
            onChange={onChange}
            label="Effective annual interest rate (%)"
            error={errors[name]?.message}
            name={name}
          />
        )}
      />
    </Dialog>
  );
}

async function proceed({fields, setError, setVisible, notify}) {
  try {
    await createDepositAccount({
      createData: {
        managerId: fields.manager_id,
        bank: fields.bank,
        iban: fields.iban,
        bic: fields.bic,
        bankExternalReference: fields.bank_external_reference,
        effectiveAnnualInterestRate: fields.effective_annual_interest_rate,
      },
    });
  } catch (err) {
    if (err.data?.length) {
      setFormErrors({
        setError,
        errors: err.data,
      });
    } else if (err.message) {
      alert({title: err.message});
    } else {
      handleError(err);
    }
    return;
  }
  notify({text: 'Deposit account was created.'});
  setVisible(false);
}
